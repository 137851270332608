import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import PageBanner from "../components/banners/pageBanner"
//import ProjectFilter from "../components/links/projectFilter"
import ProjectBlocks from "../components/archives/projectBlocks"
import Breadcrumbs from "../components/links/breadcrumbs"

const projectIndustryTemplate = props => {
	const {
		data: {
			wpgraphql: { industry }
		},
	} = props;
	const { name } = industry;

	return (
		<Layout>
			<div>
				<PageBanner 
					title={`Industry: ` + name}
					subtitle={``}
					tax={``}
				/>
				<section className="preview light">
					<Breadcrumbs title={`Portfolio`} url={`/portfolio`} />
					{/*<div className="constrained">
						<nav className="preview__nav">
							<ProjectFilter 
								taxonomies={industries}
								title='Industry'
							/>
						</nav>
					</div>*/}
					<ProjectBlocks projects={industry.projects} />
				</section>
			</div>
		</Layout>
	)
}

export default projectIndustryTemplate;

export const pageQuery = graphql`
	query GET_PROJECT_INDUSTRY($id: ID!) {
		wpgraphql {
			industry(id: $id) {
				id
				name
				slug
				projects(where: {status: PUBLISH}) {
					nodes {
						id
						title(format: RENDERED)
						uri
						featuredImage {
							node {
								sourceUrl(size: MEDIUM_LARGE)
							}
						}
						acfWork {
							  previewBlockTitle
						}
					}	
				}
			}
			industries(where: {hideEmpty: true}, first: 999) {
				nodes {
					id
					name
					uri
					slug
				}
			}
		}
	}
`